
import s from "./Discography.module.css";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";


let Discography = (props) => {
    // let TITLE = "Discography for MADNOTBAD";
    console.log(props);

    return (<div>
        <Helmet>
            <title>{props.title}</title>
        </Helmet>

        <div className={s.modal}>
            <NavLink to="/" className={s.closeBack}></NavLink>
            <div className={s.modal__content}>
                <h2 className={s.title}>Лучший год (<span className={s.album}>альбом</span>, 2010)</h2>
                <p className={s.text}>Запис: квітень-жовтень, 2010</p>
                <p className={s.text}>Випуск: Moon Records, 2021</p>

                <h2 className={s.title}>Последний день лета (<span className={s.maxi}>максі-сингл</span>, 2012)</h2>
                <p className={s.text}>Запис: весна-літо, 2012</p>
                <p className={s.text}>Випуск: Moon Records, 2021</p>

                <h2 className={s.title}>Врываясь в дома (<span className={s.album}>альбом</span>, 2013)</h2>
                <p className={s.text}>Запис: 14 та 16 жовтня, 2012; лютий-квітень, 2013</p>
                <p className={s.text}>Випуск: Moon Records, 2021</p>

                <h2 className={s.title}>Кайф (<span className={s.maxi}>максі-сингл</span>, 2013)</h2>
                <p className={s.text}>Запис: весна та осінь, 2013</p>
                <p className={s.text}>Випуск: Moon Records, 2021</p>

                <h2 className={s.title}>Мышиный король (<span className={s.single}>сингл</span>, 2014)</h2>
                <p className={s.text}>Запис: січень, 2014</p>
                <p className={s.text}>Випуск: DIY</p>

                <h2 className={s.title}>Любовь разорвет нас на куски [Joy Division cover] (<span className={s.single}>сингл</span>, 2014)</h2>
                <p className={s.text}>Запис: лютий, 2014</p>
                <p className={s.text}>Випуск: DIY</p>

                <h2 className={s.title}>Батьківщина [Гражданская оборона cover] (<span className={s.single}>сингл</span>, 2015)</h2>
                <p className={s.text}>Запис: січень-лютий, 2015</p>
                <p className={s.text}>Випуск: DIY</p>

                <h2 className={s.title}>Деньги & Юность (<span className={s.single}>сингл</span>, 2015)</h2>
                <p className={s.text}>Запис: 25 липня, 2015</p>
                <p className={s.text}>Випуск: Moon Records, 2021</p>

                <h2 className={s.title}>Звезды/Зірки [unplugged] (<span className={s.single}>сингл</span>, 2016)</h2>
                <p className={s.text}>Запис: осінь, 2016</p>
                <p className={s.text}>Випуск: Moon Records, 2021</p>

                <h2 className={s.title}>Детства моего чистые глазенки [Александр Лаэртский cover] (<span className={s.single}>сингл</span>, 2016)</h2>
                <p className={s.text}>Запис: осінь, 2016</p>
                <p className={s.text}>Випуск: DIY</p>

                <h2 className={s.title}>Дешевый трюк (<span className={s.maxi}>максі-сингл</span>, 2017)</h2>
                <p className={s.text}>Запис: січень-лютий, 2017</p>
                <p className={s.text}>Випуск: Moon Records, 2021</p>

                <h2 className={s.title}>Любити вас [на вірши М.Вінграновського] (<span className={s.single}>сингл</span>, 2018)</h2>
                <p className={s.text}>Запис: весна-літо, 2018</p>
                <p className={s.text}>Випуск: DIY</p>

                <h2 className={s.title}>Мало воздуха (<span className={s.single}>сингл</span>, 2018)</h2>
                <p className={s.text}>Запис: весна-літо, 2018</p>
                <p className={s.text}>Випуск: Moon Records, 2018</p>

                <h2 className={s.title}>З тобою (<span className={s.single}>сингл</span>, 2018)</h2>
                <p className={s.text}>Запис: осінь, 2018</p>
                <p className={s.text}>Випуск: Moon Records, 2018</p>

                <h2 className={s.title}>Зірки (<span className={s.single}>сингл</span>, 2018)</h2>
                <p className={s.text}>Запис: осінь, 2018</p>
                <p className={s.text}>Випуск: Moon Records, 2018</p>

                <h2 className={s.title}>MADNOTBAD (<span className={s.album}>альбом</span>, 2020)</h2>
                <p className={s.text}>Запис: 2018-2020</p>
                <p className={s.text}>Випуск: Moon Records, 2020</p>

                <h2 className={s.title}>Alisa (<span className={s.single}>сингл</span>, 2020)</h2>
                <p className={s.text}>Запис: осінь, 2020</p>
                <p className={s.text}>Випуск: Moon Records, 2020</p>

                <NavLink to="/" className={s.close}>x</NavLink>
            </div>
        </div>
    </div>
    )
}

export default Discography;