const ListenOnStreaming = () => {
	return (
		<div className="digital-wrap">
			<div className="logo-small"></div>
			<a
				target="_blank"
				rel="noreferrer"
				href="https://ffm.to/mv6z8jo"
				className="digital-link"
			>
				Click here to listen on streaming
			</a>

			<div className="logo-small"></div>
		</div>
	)
}

export default ListenOnStreaming;